interface User {
  userId: string;
  username: string;
  email: string;
}

const devUsers: User[] = [
  {
    userId: '27840af8-0011-7058-f3ab-9aac3ebc60cd',
    username: 'dev-ark-test-manager',
    email: 'dev-ark-test-manager@qol-system.co.jp',
  },
  {
    userId: 'b7543a08-0041-7014-354d-92373f0db649',
    username: 'dev-ark-test',
    email: 'dev-ark-test@qol-system.co.jp',
  },
];

const stgUsers: User[] = [
  {
    userId: '47848a78-1031-7079-eacb-2f49737d31c9',
    username: 'shimizu',
    email: 'shimizu@qol-system.co.jp',
  },
  {
    userId: '27949aa8-2031-7022-fcb0-e92e8ad6c267',
    username: 'kase',
    email: 'kase@qol-system.co.jp',
  },
  {
    userId: '37b42ad8-b0a1-70ec-3ed3-0ac2a9e47722',
    username: 'ohtsu',
    email: 'ohtsu@qol-system.co.jp',
  },
  {
    userId: '37f41a18-40f1-7081-255d-96c6df8d96b9',
    username: 'hayama',
    email: 'hayama@qol-system.co.jp',
  },
  {
    userId: 'e7641ac8-6001-7076-5e8a-04aff0a12c1b',
    username: 'takamoto',
    email: 'takamoto@qol-system.co.jp',
  },
  {
    userId: '07441a68-a0d1-7073-d41e-6ae0fe0d3c5b',
    username: 'takeda',
    email: 'takeda@qol-system.co.jp',
  },
  {
    userId: '77c4aa08-50d1-700d-1182-ca375ab4b86a',
    username: 'kojima',
    email: 'kojima@qol-system.co.jp',
  },
  {
    userId: '87b43a68-60f1-7003-99a3-5ffb37bdcf6c',
    username: 'suresh',
    email: 'suresh@qol-system.co.jp',
  },
  {
    userId: '27441a48-20c1-709d-b3f9-40891419d6aa',
    username: 'horikawa',
    email: 'horikawa@qol-system.co.jp',
  },
  {
    userId: '17041a18-7021-7011-48b2-49ccbbeda4f3',
    username: 'bando',
    email: 'bando@qol-system.co.jp',
  },
  {
    userId: 'b7143a38-c061-7005-8d73-c704d5abb5fc',
    username: 'ishibashi',
    email: 'ishibashi@qol-system.co.jp',
  },
  {
    userId: 'b724ea78-e061-706b-c156-23c1d7cf5acd',
    username: 'arai',
    email: 'arai@qol-system.co.jp',
  },
  {
    userId: '77d43a98-f0a1-7085-44ba-0e258904c2b9',
    username: 'rawat',
    email: 'rawat@qol-system.co.jp',
  },
  {
    userId: 'c7f48a08-b031-704d-e171-e34a30360459',
    username: 'iwano',
    email: 'iwano@qol-system.co.jp',
  },
  {
    userId: '17a4ea78-b0b1-70b3-a884-eac528c182b5',
    username: 'sugiyama',
    email: 'sugiyama@qol-system.co.jp',
  },
  {
    userId: '87849af8-b031-702c-502e-184a4ba861b0',
    username: 'endo',
    email: 'endo@qol-system.co.jp',
  },
  {
    userId: '37440a78-40e1-701d-3772-9d7e430575f0',
    username: 'kasuga',
    email: 'kasuga@qol-system.co.jp',
  },
];

export const getUsers = (): User[] => {
  return process.env.REACT_APP_ENV === 'dev' ? devUsers : stgUsers;
};

export type { User };
