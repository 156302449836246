const awsConfigBase = {
  aws_project_region: 'ap-northeast-1',
  aws_appsync_region: 'ap-northeast-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cognito_region: 'ap-northeast-1',
  oauth: {},
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
};

const awsConfigDev = {
  aws_appsync_graphqlEndpoint: 'https://api-dev2.ark-system.net/graphql',
  aws_cognito_identity_pool_id: 'ap-northeast-1:739218a1-8323-47f4-acc9-a9f0974ed4ea',
  aws_user_pools_id: 'ap-northeast-1_Ukw9vsj76',
  aws_user_pools_web_client_id: '1rdno4emb435nvnobn80s6hcb6',
};

const awsConfigStg = {
  aws_appsync_graphqlEndpoint: 'https://api-stg.ark-system.net/graphql',
  aws_cognito_identity_pool_id: 'ap-northeast-1:047025a0-45c3-40fa-b7ef-331bb0751688',
  aws_user_pools_id: 'ap-northeast-1_aY0UowGOB',
  aws_user_pools_web_client_id: '4nq8o02qfn2mo003do9d1m4vmc',
};

const awsConfig = (() => {
  switch (process.env.REACT_APP_ENV) {
    case 'stg':
      return { ...awsConfigBase, ...awsConfigStg };
    case 'dev':
    default:
      return { ...awsConfigBase, ...awsConfigDev };
  }
})();

export default awsConfig;
